@import url('https://fonts.googleapis.com/css2?family=Figtree:wght@300;400;500;600&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

/* ----------------------------------------------
 * Generated by Animista on 2024-8-28 19:44:44
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation bounce-in-left
 * ----------------------------------------
 */
@-webkit-keyframes bounce-in-left {
    0% {
        -webkit-transform: translateX(-600px);
        transform: translateX(-600px);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
        opacity: 0;
    }
    38% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
        opacity: 1;
    }
    55% {
        -webkit-transform: translateX(-68px);
        transform: translateX(-68px);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
    }
    72% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
    }
    81% {
        -webkit-transform: translateX(-28px);
        transform: translateX(-28px);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
    }
    90% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
    }
    95% {
        -webkit-transform: translateX(-8px);
        transform: translateX(-8px);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
    }
    100% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
    }
}
@keyframes bounce-in-left {
    0% {
        -webkit-transform: translateX(-600px);
        transform: translateX(-600px);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
        opacity: 0;
    }
    38% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
        opacity: 1;
    }
    55% {
        -webkit-transform: translateX(-68px);
        transform: translateX(-68px);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
    }
    72% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
    }
    81% {
        -webkit-transform: translateX(-28px);
        transform: translateX(-28px);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
    }
    90% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
    }
    95% {
        -webkit-transform: translateX(-8px);
        transform: translateX(-8px);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
    }
    100% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
    }
}


@keyframes pulse-glow {
    0%, 100% {
        box-shadow: 0 0 10px rgba(255, 165, 0, 0.8), 0 0 20px rgba(255, 165, 0, 0.6);
    }
    50% {
        box-shadow: 0 0 20px rgba(255, 165, 0, 1), 0 0 30px rgba(255, 165, 0, 0.8);
    }
}

@keyframes flash {
    0%, 100% {
        opacity: 1;
    }
    50% {
        opacity: 0.5;
    }
}

@keyframes pulse-glow-flash {
    0% {
        box-shadow: 0 0 0 rgba(255, 165, 0, 0.0);
    }
    20% {
        box-shadow: 0 0 20px rgba(255, 165, 0, 0.6), 0 0 30px rgba(255, 165, 0, 0.8);
    }
    40% {
        box-shadow: 0 0 10px rgba(255, 165, 0, 0.4), 0 0 20px rgba(255, 165, 0, 0.3);
    }
    60% {
        box-shadow: 0 0 20px rgba(255, 165, 0, 1.0), 0 0 30px rgba(255, 165, 0, 0.8);
    }
    100% {
        box-shadow: 0 0 0 rgba(255, 165, 0, 0.0);
    }
}

@keyframes hue-rotate-animation {
    0% {
        filter: hue-rotate(0deg);
    }
    50% {
        filter: hue-rotate(-120deg);
    }
    100% {
        filter: hue-rotate(0deg);
    }
}

@keyframes grow {
    from {
        width: 0;
    }
    to {
        width: 100%;
    }
}


@layer base {
    h1 {
        @apply text-3xl font-semibold mb-6 uppercase;
    }
    h2 {
        @apply text-xl font-semibold mb-4 uppercase;
    }
    h1, h2, h3, h4, h5, h6 {
        @apply dark:text-white;
    }
    p {
        @apply mb-4;
    }
}

@layer components {
    .blended-background {
        background-image: url('../public/img/tesselation-test.png');
        background-repeat: repeat;
        background-blend-mode: difference;
        @apply bg-white dark:bg-slate-900;
    }

    .dark .blended-background {
        background-blend-mode: exclusion;
    }

    .link {
        @apply text-purple-900 dark:text-purple-500 hover:underline hover:text-black;
    }

    .text-input, .form-input {
        @apply border border-gray-300 rounded-md p-2 text-gray-700 dark:text-gray-700;
    }

    .full-width-background {
        @apply -mx-[100vw] px-[100vw]
    }

    .bounce-in-left {
        -webkit-animation: bounce-in-left 1s both;
        animation: bounce-in-left 1s both;
    }

    .highlighted {
        @apply rounded-md border shadow-md bg-orange-100/75 border-orange-300 dark:bg-orange-300/80 dark:border-orange-400;
    }

    .glow-flash {
        animation: pulse-glow-flash 2.5s ease-in-out;
    }

    .animate-hue-rotate {
        animation: hue-rotate-animation 3.5s ease-in-out;
    }

    .donut-text, .donut-line, .sankey-label-text {
        @apply dark:stroke-gray-200
    }

    .donut-line {
        opacity: 0.3;
    }

    .dark .react-datepicker__current-month {
        color: #000;
    }
}